<template>
  <el-dialog
    :visible.sync="dialogVisible"
    center
    width="30%"
    :close-on-click-modal="false"
    :title="isEdit ? '编辑车辆资产' : '添加车辆资产'"
    @close="cancel"
  >
    <el-form
      size="small"
      label-width="100px"
      ref="formRef"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="车牌号" prop="plate">
        <el-input v-model="form.plate"></el-input>
      </el-form-item>
      <el-form-item label="车辆类型" prop="vehicleType">
        <el-select v-model="form.vehicleType" style="width: 100%">
          <el-option
            v-for="item in truckTypeOptions"
            :key="item.val"
            :label="item.val"
            :value="item.val"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="租赁合同" prop="leaseId">
        <el-select v-model="form.leaseId" style="width: 100%" filterable>
          <el-option
            v-for="item in leaseOptions"
            :key="item.id"
            :label="item.leaseContract"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="贷款合同" prop="loanId">
        <el-select v-model="form.loanId" style="width: 100%" filterable>
          <el-option
            v-for="item in loanOptions"
            :key="item.id"
            :label="item.loanContract"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        v-if="isEdit"
        class="buttons"
        size="small"
        type="primary"
        @click="confirmUpdate"
        >确 定</el-button
      >
      <el-button
        v-else
        class="buttons"
        size="small"
        type="primary"
        @click="confirmAdd"
        >确 定</el-button
      >
      <el-button class="buttons" size="small" @click="cancel">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import ImgUpload from "@/components/Img-upload.vue";
export default {
  data() {
    return {
      dialogVisible: true,
      isEdit: false,
      id: "",
      form: {
        plate: "",
        vehicleType: "",
        leaseId: "",
        loanId: "",
      },
      truckTypeOptions: [],
      leaseOptions: [],
      loanOptions: [],
      editBeginOptions: {
        disabledDate: (time) => {
          if (!this.form.loanEnd) {
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            let endTime = new Date(this.form.loanEnd);
            endTime.setDate(endTime.getDate() - 1);
            return time.getTime() > endTime;
          }
        },
      },
      editEndOptions: {
        disabledDate: (time) => {
          //开始时间可以和结束时间相同增加
          let startTime = new Date(this.form.loanBegin);
          startTime.setDate(startTime.getDate());
          return (
            time.getTime() < startTime ||
            time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
          );
        },
      },
      rules: {
        leaseId: [
          {
            required: true,
            message: "请选择租赁合同",
            trigger: ["blur", "change"],
          },
        ],
        loanId: [
          {
            required: true,
            message: "请选择贷款合同",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  components: {
    ImgUpload,
  },
  created() {
    this.getTruckTypeOptions();
    this.getLeaseOptions();
    this.getLoanOptions();
  },
  methods: {
    async getTruckTypeOptions() {
      const { data } = await this.$http("api1", "/sys/dic/list", "get", {
        dictionaryType: "车辆类型",
      });
      this.truckTypeOptions = data.data;
    },
    async getLeaseOptions() {
      const { data } = await this.$http(
        "api1",
        "/api/asset/Lease/list",
        "get",
        {}
      );
      this.leaseOptions = data.data.list;
    },
    async getLoanOptions() {
      const { data } = await this.$http(
        "api1",
        "/api/asset/Loan/list",
        "get",
        {}
      );
      this.loanOptions = data.data.list;
    },
    confirmAdd() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          const { data } = await this.$http(
            "api1",
            "/api/asset/VehicleInfo/save",
            "post",
            this.form
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.$emit("confirm");
          }
        }
      });
    },
    confirmUpdate() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          const { data } = await this.$http(
            "api1",
            "/api/asset/VehicleInfo/update",
            "post",
            {
              id: this.id,
              ...this.form,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.$emit("confirm");
          }
        }
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
